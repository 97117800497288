import * as React from "react"
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import "./styles.css";
import styled from 'styled-components';
import Hero from '../images/cottage.jpg';
import Home from '../images/home.svg';
import Commerical from '../images/commerical.svg';
import { Helmet } from "react-helmet";

const Header = styled.div`
`;

const HeroImg = styled.img`
  height: 500px;
  width: 100%;
  object-fit: cover;

  @media screen and (max-width: 600px) {
    height: 300px;
  }
`;

const TitleBox = styled.div`
    background: #e0e0e0;
    position: relative;
    top: -100px;
    max-width: 500px;
    height: 200px;
    opacity: 90%;

    @media screen and (max-width: 600px) {
      max-width: 75%;
      height: 150px;
    }
`;

const Heading = styled.h1`
  padding:60px 40px 40px 0;
  font-size:50px;
  text-align: center;
  font-family: 'Vollkorn', serif;
  margin: 0;

  @media screen and (max-width: 600px) {
    font-size:40px;
  }
`;

const ServiceSection = styled.div`
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  display: block;
  margin-top:-80px;
  padding-bottom: 80px;
`;

const ThreeColumns = styled.div`
display: flex;
flex-direction: row;
// flex-wrap: wrap;
width: 100%;

@media screen and (max-width: 800px) {
  display: block;
}

`;

const Column = styled.div`
flex: 33.33%;
background: white;
color: #112544;
font-family: 'Poppins', sans-serif;
padding: 20px;
margin: 20px;

ul {
  padding-left: 20px;
}

li {
  font-size: 14px;
}

@media screen and (max-width: 800px) {
  flex:100%;
}
`;

const TwoColumn = styled(Column)`
flex: 50%;

`;


const IconImg = styled.img`
max-width: 50px;
width: 100%;
`;

const Insurance = () => {
  return (
    <main>
      <Helmet>
      <title>Products</title>
      <meta name="description" content="Dunning Currie Insurance Inc. is an independently owned Canadian Property &amp; Casualty premier insurance brokerage. " />
      <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
      <meta charSet="utf-8" />
      </Helmet>
      <Navigation />
      <Header>
         <HeroImg src={Hero} alt="hero image" />
          <TitleBox><Heading>Insurance</Heading></TitleBox>
      </Header>
          <ServiceSection>
          <ThreeColumns>
              <TwoColumn>
              <IconImg src={Commerical} alt="" />
              <h3>Business Insurance</h3>
             <ul>
               <li>Commercial Property</li>
               <li>Commercial General &amp; Umbrella Liability</li>
               <li>Commercial Automobile</li>
               <li>Builder’s Risk / Course of Construction</li>
               <li>Professional Liability/Errors &amp; Omissions</li>
               <li>Director/Management Liability</li>
               <li>Cyber/Privacy Liability</li>
             </ul>
              </TwoColumn>
              <TwoColumn>
              <IconImg src={Home} alt="" />
              <h3>Personal Insurance</h3>
             <ul>
               <li>Property/Home Insurance</li>
               <li>Secondary/Seasonal Property Insurance</li>
               <li>Personal Automobile</li>
               <li>Recreational Vehicles</li>
               <li>Personal Umbrella/Excess</li>
               <li>Valuable Articles</li>
             </ul>
              </TwoColumn>
          </ThreeColumns>
          </ServiceSection>
      <Footer />
    </main>
  )
}

export default Insurance

export const Head = () => <title>Home Page</title>
